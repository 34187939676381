/*global google*/
import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import InterestPlaceMarker from './InterestPlaceMarker.js';

let markers = [];

class InterestPlaceListMarkers extends Component{
    
    constructor(props) {
        super(props);
        this.state = { 
            places: this.props.places,
            activeMarker: this.props.activeMarker,
            hideMiddlePointMarker: this.props.hideMiddlePointMarker,
            midpoint: this.props.midPointCoord
        };
    }    

    /**
     * Render middle point of a route
     */
    renderMiddlePointMarker() {
        let marker = null;
        if (this.state.midpoint.lat && this.state.midpoint.lng) {
            marker =  
            <Marker 
                key = { Math.random() }
                position={{ lat: this.state.midpoint.lat, lng: this.state.midpoint.lng }} 
                defaultAnimation = {1}
                icon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAABhklEQVRYw+2VQUrDQBSGewF1LXgA9QrddSal050LabJzoe0N0iYHaJeJgq7UgpYiiLeQNrbdhdoruNWNmAbj+6MFxWDjwknAefBgmAzMN/9770+h8B/CY2x7wJg1ZKyLjNecb2UONuF8zeP8iqBeR5VK4BtGONX1cCREgD361veEWM0OTtNmY4J5aDajZ8f5ktgbEzSduc8EEsoB7qnT+Qa3yMd2O4akcvek9xxKmKRckpI4OyiXN6UB0oX2pFp9WQa3SPQkKd6SCdj1aRjSAvq1Wkiqn8kD5Pwcl/4CcE6POpVaYthK6hJjmqWWmEw49ZCYZjQsleQOyYfN9GEhsJIfbQYDwtilfB8k84UJAzLRqEm5GE7TprfF4kohiwAkTDj+1Qkxp8mOkHdChCgrlMsM7nOgv252jN6Fvhchr2ktveeWRb3l7tYtJ4qT1rmCU4AKUAEqQAWoABWgAlSAClABKsDMAW1nvWG5QcN2g33zaCN3gO+QhycHtnucSzgElMuten8Vb9bMZQfqQXfSAAAAAElFTkSuQmCC' >                
                
                { this.state.hideMiddlePointMarker ?
                
                    <InfoWindow>
                        <div style={{ padding: 12 }}>
                            <span>Middle meeting point!</span>
                        </div>
                    </InfoWindow>
                : null }
            </Marker>;
        }

        markers.push(marker);
    }

	closeOtherMarkers = (id) => {
		this.setState({ activeMarker: id, hideMiddlePointMarker: false });
    }    
    
    componentWillReceiveProps(nextProps){
        this.closeOtherMarkers(null);
        this.setState({activeMarker: nextProps.activeMarker})
    }
    
    render() {
        markers = this.props.places.map( place => {
            let marker = <InterestPlaceMarker
                            key = { place.id }
                            id = { place.id }
                            closeMarkers = { this.closeOtherMarkers }
                            toggleShowPage = { this.props.toggleShowPage }
                            place = { place }
                            position = {{ lat: place.lat, lng: place.lng }}
                            activeMarker = { place.id === this.state.activeMarker ? true : false }
                            onToggleOpen = { this.onToggleOpen }
                          />
            return marker;
        });
        
        { this.renderMiddlePointMarker() }        

        return markers;

    }
}

export default InterestPlaceListMarkers;