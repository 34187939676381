import React, { Component } from 'react';

class LottiePlayer extends Component{      
  render(){
      const src = this.props.src;
      const divStyle = this.props.divStyle;

    return(                       
        <div style={divStyle}>
            <lottie-player 
                autoplay
                src= {src}
                background="transparent" 
                speed="1" 
                loop  >
            </lottie-player>
        </div>
    )
  }
}

export default LottiePlayer;