import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import onClickOutside from "react-onclickoutside";

class Dropdown extends Component{
  constructor(props){
    super(props)
    this.state = {
      listOpen: false,
      headerTitle: this.props.title
    }
  }
  
  componentDidMount(){
    let defaultChoice = null;
    defaultChoice = this.props.list.find(choice => choice.selected === true);    
    this.selectItem(defaultChoice.title, defaultChoice.id, defaultChoice.key)    
  }

  handleClickOutside(e){
    this.setState({
      listOpen: false
    })
  }

  selectItem = (title, id, stateKey) => {
    this.setState({
      headerTitle: title,
      listOpen: false
    }, this.props.resetThenSet(id, stateKey))
  }

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  }  

  render(){    
    const{list} = this.props
    const{listOpen, headerTitle} = this.state
    return(
      <div className="dd-wrapper">
        <div className="dd-header" onClick={this.toggleList} style={ listOpen ? {borderColor: '#267dc0', boxShadow: '0 0 0 transparent'} : {}}>
          <div className="row dd-content">

            <div className="dd-header-title col-10">{headerTitle}</div>
            <div className="col-2"> 
              {listOpen
                ? <FontAwesomeIcon className="dd-icon" icon="angle-up" size="1x"/> 
                : <FontAwesomeIcon className="dd-icon" icon="angle-down" size="1x" />
              }
            </div>

          </div>
        </div>

        {listOpen && <ul className="dd-list">
          {list.map((item)=> (
            <li className="dd-list-item" key={ item.id } onClick={ () => this.selectItem(item.title, item.id, item.key) }>
                { item.title } { item.selected }
            </li>
          ))}
        </ul>}
      </div>
    )
  }
}

export default onClickOutside(Dropdown);
