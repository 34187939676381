/*global google*/
import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { withGoogleMap, GoogleMap, Marker, DirectionsRenderer, InfoWindow  } from 'react-google-maps';
import Geosuggest from 'react-geosuggest';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp, faAngleDown, faDollarSign, faGlassMartiniAlt, faFilm, faCoffee, faEye, faTree, faDrumstickBite, faLeaf } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';

import Dropdown from './components/tools/Dropdown.js';
import Messages from './components/tools/Messages.js';
import LottiePlayer from './components/tools/Lottieplayer.js';
import InterestPlaceListMarkers from './components/map/InterestPlaceListMarkers.js'
import InterestList from './components/InterestList.js'
import './App.css';

let loadCount = 0;
let loadPlaceService = 0;
let loadDirectionService = 0;
const DirectionsService = new google.maps.DirectionsService(); 

class App extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            pointA: {},
            pointB: {}, 
            currentPosition: {},
            midpoint: {},
            latitudeAddressA: 44.841479,
            longitudeAddressA: -0.569940,
            directions: null,
            choosenTravelMode: google.maps.TravelMode.WALKING,
            travelMode: [
                {
                    id: 0,
                    value: google.maps.TravelMode.BICYCLING,
                    title: 'Bicycling',
                    selected: false,
                    key: 'travelMode'
                },
                {
                    id: 1,
                    value: google.maps.TravelMode.DRIVING,
                    title: 'Driving',
                    selected: false,
                    key: 'travelMode'
                },
                {
                    id: 2,
                    value: google.maps.TravelMode.TRANSIT,
                    title: 'Transit',
                    selected: false,
                    key: 'travelMode'
                },
                {
                    id: 3,
                    value: google.maps.TravelMode.WALKING,
                    title: 'Walking',
                    selected: true,
                    key: 'travelMode'
                }
            ],
            choosenInterestType: 'restaurant',
            interestTypesPlace: [
                {
                    id: 0,
                    value: 'bar',
                    title: 'Bar',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'glass-martini-alt' 
                },
                {
                    id: 1,
                    value: 'cafe',
                    title: 'Cafe',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'coffee'
                },
                {
                    id: 2,
                    value: 'florist',
                    title: 'Florist',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'leaf'
                },
                {
                    id: 3,
                    value: 'movie_theater',
                    title: 'Cinema',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'film'
                },
                {
                    id: 4,
                    value: 'museum',
                    title: 'Museum',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'eye'
                },
                {
                    id: 5,
                    value: 'park',
                    title: 'Park',
                    selected: false,
                    key: 'interestTypesPlace',
                    icon: 'tree'
                },
                {
                    id: 6,
                    value: 'restaurant',
                    title: 'Restaurant',
                    selected: true,
                    key: 'interestTypesPlace',
                    icon: 'drumstick-bite'
                }
            ],
            choosenRadiusPerimeter: 500,
            radiusPerimeter: [
                {
                    id: 0,
                    value: 500,
                    title: '500 meters',
                    selected: true,
                    key: 'radiusPerimeter'
                },
                {
                    id: 1,
                    value: 1000,
                    title: '1000 meters',
                    selected: false,
                    key: 'radiusPerimeter'
                },
                {
                    id: 2,
                    value: 1500,
                    title: '1500 meters',
                    selected: false,
                    key: 'radiusPerimeter'
                },                
                {
                    id: 3,
                    value: 2000,
                    title: '2000 meters',
                    selected: false,
                    key: 'radiusPerimeter'
                }
            ],            
            message: {
                messageType: '',
                messageContent: '',
                isShowed: false
            },
            interestPlaces: [],
            interestPlacesLocation: [],
            activeMarker: null,
            hideMiddlePointMarker: false
        };

        this.onSuggestSelectPointA = this.onSuggestSelectPointA.bind(this)
        this.onSuggestSelectPointB = this.onSuggestSelectPointB.bind(this)
        this.getDirection = this.getDirection.bind(this);
        this.getNearestInterestPoint = this.getNearestInterestPoint.bind(this);
        this.renderInterestPointMarkers = this.renderInterestPointMarkers.bind(this);
        this.renderInterestList = this.renderInterestList.bind(this);
        this.showInfo = this.showInfo.bind(this);
        
        library.add(faAngleUp, faAngleDown, faDollarSign, faGlassMartiniAlt, faFilm, faCoffee, faEye, faTree, faDrumstickBite, faLeaf);
    }    

    componentDidMount(){   
        //this.getGeoLocation();     
    }
    
    // shouldComponentUpdate(nextProps, nextState) {

    //     console.log('nextProps: ', nextProps);
    //     console.log('nextState: ', nextState);

    //     let currentPositionSelected = ( nextState.currentPosition.lng && nextState.currentPosition.lat ) ? true : false;
    //     let isPointAValid = currentPositionSelected || nextState.pointA.placeId ? true : false; 

    //     if( currentPositionSelected ){            
            

    //     }

    //     if ( !isPointAValid 
    //         || !nextState.pointB.placeId 
    //         || nextState.choosenTravelMode === ""
    //         || nextState.choosenInterestType === ""
    //         || !nextState.choosenRadiusPerimeter ) { 

    //         return false;
    //     }

    //     return true;
    // }

    /**
      * When the input receives focus
      */
    onFocus() {
        // TODO
    }

    /**
     * When the input loses focus
     */
    onBlur(value) {
        // TODO
    }

    /**
     * When the input got changed
     */
    onChange(value) {
        // TODO
    }

    /**
     * When a suggest got selected
     */
    onSuggestSelectPointA(suggest) {
        this.setState({ pointA: suggest }, () => {
            this.getDirection();
        });
    }

    /**
     * When a suggest got selected
     */
    onSuggestSelectPointB(suggest) {
        console.log('----> onSuggestSelectPointB');
        this.setState({ pointB: suggest }, () => {
            this.getDirection();            
        });
    }

    /**
     * When there are no suggest results
     */
    onSuggestNoResults(userInput) {
        // TODO
    }    

    /**
     * Get the current geo location
     */
    getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState({
                        currentPosition: {
                            lat: position.coords.latitude, 
                            lng: position.coords.longitude 
                        },
                        latitudeAddressA: position.coords.latitude,
                        longitudeAddressA: position.coords.longitude,
                        pointA: {
                            location: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        }

                    }, () => {
                        this.getDirection();
                    });
                }
            )
        } else {
            console.error('error fetching current location');
        }
    }

    /**
     * Render interest point markers of the middle point of a route
     */
    renderInterestPointMarkers() {
        if (this.state.interestPlacesLocation && this.state.interestPlacesLocation.length > 0) {
            return <InterestPlaceListMarkers 
                        places = { this.state.interestPlacesLocation }
                        closeOtherMarkers = { this.closeOtherMarkers }
                        activeMarker = { this.state.activeMarker }
                        hideMiddlePointMarker = { this.state.hideMiddlePointMarker }
                        midPointCoord = { this.state.midpoint }
                    />;
        }
        return null;
    }

    showInfo(selectedItemId) {
    }
        
    /**
     * Render interest list of the middle point of a route
     */
    renderInterestList(){
        if (this.state.interestPlacesLocation && this.state.interestPlacesLocation.length > 0) {
            let choosenInterestTypeObject = _.find(this.state.interestTypesPlace, ['value', this.state.choosenInterestType]);
            return <InterestList 
                        places={ this.state.interestPlacesLocation } 
                        activeMarker={ this.state.activeMarker }
                        choosenInterestType = { choosenInterestTypeObject }
                        />;
        }
        return null;
    }    

    /**
     * Get direction from Google API
     */
    getDirection(){
        let adresseOriginA = this.state.pointA;
        let adresseOriginALocation = adresseOriginA ? this.state.pointA.location : null;
        let adresseOriginB = this.state.pointB;
        let adresseOriginBLocation = adresseOriginB ? this.state.pointB.location : null;
        let routesPaths = [];
        let middle = {};

        if ((adresseOriginA && adresseOriginALocation) && (adresseOriginB && adresseOriginBLocation)) {                       

            DirectionsService.route({
                origin: new google.maps.LatLng(adresseOriginALocation.lat, adresseOriginALocation.lng),
                destination: new google.maps.LatLng(adresseOriginBLocation.lat, adresseOriginBLocation.lng),
                travelMode: this.state.choosenTravelMode,
            }, (result, status) => {        

                // Google event tag
                window.gtag('event', 'getDirections', {
                    'event_category': 'Directions',
                    'event_label': 'Get route'
                });
                
                loadDirectionService++;
                console.log('-----====== DirectionsService.route ======----->: #', loadDirectionService);

                if (status === google.maps.DirectionsStatus.OK) {
                    routesPaths = result.routes[0].overview_path;
                    middle = routesPaths[Math.floor(routesPaths.length / 2)];

                    this.getNearestInterestPoint({lat: parseFloat(middle.lat()), lng: parseFloat(middle.lng())}, result);                    

                } else if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
                    console.error('error fetching directions (ZERO_RESULTS): ', result);

                    loadCount++;

                    this.setState({ message: {
                        messageType: 'error',
                        messageContent: 'There is no route avalaible my friend. Did you fill the box correctly ?',
                        isShowed: true
                    }});

                } else if(status === 'MAX_ROUTE_LENGTH_EXCEEDED'){
                    console.error('error route too long (crazy...): ', result);
                    
                    loadCount++;

                    this.setState({ message: {
                        messageType: 'error',
                        messageContent: 'Requested route is too far. (Are you insane ?)',
                        isShowed: true
                    }});
                }
                else {
                    console.error('error fetching directions (check it boy): ', result);
                }
            });


        } else {
            // TODO: RELOAD MAP WITH MARKER OF THE ONLY SPECIFIED ADDRESS
        }
    }

    /**
     * Get nearest interest point from Google API
     */
    getNearestInterestPoint(mapPoint, direction) {
        if(mapPoint.lat && mapPoint.lng && direction){            
            const PlacesService = new google.maps.places.PlacesService(document.createElement('div'));
            let locations = [];
            let message = {
                messageType: '',
                messageContent: '',
                isShowed: false
            }            

            PlacesService.nearbySearch({
                location : mapPoint,
                radius: this.state.choosenRadiusPerimeter,
                type : this.state.choosenInterestType
            }, (results, status) => {

                // Google event tag
                window.gtag('event', 'getPointsOfInterest', {
                    'event_category': 'Places',
                    'event_label': 'Get points of interest'
                });

                let placeLocation = null;
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    _.each(results, function(place, index){
                        placeLocation = place.geometry.location; 
                        let location = {
                            id: index,
                            lat: placeLocation.lat(),
                            lng: placeLocation.lng(),
                            name: place.name,
                            address: place.vicinity,
                            opening_hours: place.opening_hours,
                            price_level: place.price_level,
                            rating: place.rating,
                            photos: place.photos,
                            icon: place.icon,
                            place_id: place.place_id
                        };
                        locations.push(location);
                    });
                } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS){                    
                    let choosenInterestTypeObject = _.find(this.state.interestTypesPlace, ['value', this.state.choosenInterestType]);
                    let messageContent = "There aren't any " + choosenInterestTypeObject.title.toLowerCase() + " at proximity of your middle point. Try again with a larger radius :)"
                    if(this.state.choosenRadiusPerimeter === 2000){
                        messageContent = "There aren't any " + choosenInterestTypeObject.title.toLowerCase() + " at proximity of your middle point. Unfortunately this is the maximum perimeter :("
                    }

                    message = {
                        messageType: '',
                        messageContent: messageContent,
                        isShowed: true
                    }
                }
            
                let sortedLocations = _.orderBy(locations, ['rating'],['desc']);

                
                loadCount++;
                loadPlaceService++;
                console.log('-----====== PlacesService.nearbySearch ======----->: #', loadPlaceService);

                this.setState({ interestPlacesLocation: sortedLocations, 
                                directions: direction, 
                                midpoint: { lat: mapPoint.lat, lng: mapPoint.lng },
                                hideMiddlePointMarker: true,
                                message: message
                            });
            });      
        }          
    }

    /**
     * Drop down list reset then set function. 
     * Update the page component after a selection.
     */
    resetThenSet = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;

        this.setState({
            [key]: temp,
            choosenTravelMode: _.find(temp, ['selected', true]).value
        }, () => {
                this.getDirection();
            }
        );
    }

    /**
     * Drop down list reset then set function. 
     * Update the page component after a selection.
     */
    resetInterestPlaceThenSet = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;

        this.setState({
            [key]: temp,
            choosenInterestType: _.find(temp, ['selected', true]).value
        }, () => {
                if(this.state.midpoint && this.state.directions){
                    this.getNearestInterestPoint(this.state.midpoint, this.state.directions);
                }
            }
        );
    }    

    /**
     * Drop down list reset then set function. 
     * Update the page component after a selection.
     */
    resetRadiusPerimeterThenSet = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;

        this.setState({
            [key]: temp,
            choosenRadiusPerimeter: _.find(temp, ['selected', true]).value
        }, () => {
                if(this.state.midpoint && this.state.directions){
                    this.getNearestInterestPoint(this.state.midpoint, this.state.directions);
                }
            }
        );
    }

    /**
     * Render part
     */
    render() {
        let fixtures = [
            { label: 'Paris', location: { lat: 48.862725, lng: 2.287592000000018 } },
            { label: 'Bordeaux', location: { lat: 44.841479, lng: -0.569135899999992 } },
            { label: 'Dakar', location: { lat: 14.6697094, lng: -0.569135899999992 } },
            { label: 'New York', location: { lat: 40.7033127, lng: -17.432011099999954 } },
            { label: 'Rio', location: { lat: -22.066452, lng: -42.9232368 } },
            { label: 'Tokyo', location: { lat: 35.673343, lng: 139.710388 } },
            { label: 'Bali', location: { lat: -8.3405389, lng: 115.09195090000003 } }
        ];        

        // from https://img.icons8.com/offices/40/000000/map-pin.png
        let markerIconBas64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABmJLR0QA/wD/AP+gvaeTAAABhklEQVRYw+2VQUrDQBSGewF1LXgA9QrddSal050LabJzoe0N0iYHaJeJgq7UgpYiiLeQNrbdhdoruNWNmAbj+6MFxWDjwknAefBgmAzMN/9770+h8B/CY2x7wJg1ZKyLjNecb2UONuF8zeP8iqBeR5VK4BtGONX1cCREgD361veEWM0OTtNmY4J5aDajZ8f5ktgbEzSduc8EEsoB7qnT+Qa3yMd2O4akcvek9xxKmKRckpI4OyiXN6UB0oX2pFp9WQa3SPQkKd6SCdj1aRjSAvq1Wkiqn8kD5Pwcl/4CcE6POpVaYthK6hJjmqWWmEw49ZCYZjQsleQOyYfN9GEhsJIfbQYDwtilfB8k84UJAzLRqEm5GE7TprfF4kohiwAkTDj+1Qkxp8mOkHdChCgrlMsM7nOgv252jN6Fvhchr2ktveeWRb3l7tYtJ4qT1rmCU4AKUAEqQAWoABWgAlSAClABKsDMAW1nvWG5QcN2g33zaCN3gO+QhycHtnucSzgElMuten8Vb9bMZQfqQXfSAAAAAElFTkSuQmCC'
        // const GoogleMapExample = withGoogleMap(props => (
        //     <GoogleMap
        //         defaultCenter={{ lat: this.state.latitudeAddressA, lng: this.state.longitudeAddressA }}
        //         defaultZoom={17} clickableIcons={true}>

        //         { console.log('====> loadCount: ', loadCount) }
                
        //         {!this.state.directions && <Marker 
        //                                         position={ { lat: this.state.latitudeAddressA, lng: this.state.longitudeAddressA }} 
        //                                         defaultAnimation = {1}
        //                                         icon = {markerIconBas64} /> }
        //         { this.state.directions && <DirectionsRenderer directions={ this.state.directions } /> }
        //         { this.renderInterestPointMarkers() }   
        //     </GoogleMap>
        // ));

        const divLottieSize = {
            width: '300px',
            height: '200px',
        };        

        return (
            <HttpsRedirect>
                <div className="App">
                    <div className="container">                  
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <header className="appHeader">
                                    <h1 className="headerTitle">U Meet Me</h1>
                                    <LottiePlayer src="https://assets5.lottiefiles.com/datafiles/uihIaQIvWBfYL9a/data.json"
                                                divStyle = {divLottieSize} />
                                    <h3 className="subheader">
                                        Find the (almost) closest meeting point!
                                    </h3>
                                </header>   
                            </div>                 
                        </div>

                        <Messages message={this.state.message} />

                        {/* <div className="row mb-4">
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Geosuggest
                                    fixtures={fixtures}
                                    placeholder={ this.state.pointA ? (this.state.pointA.location ? "Your location" : "First place") : "First place" }
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onChange={this.onChange}
                                    onSuggestSelect={this.onSuggestSelectPointA}
                                    onSuggestNoResults={this.onSuggestNoResults}
                                    location={new google.maps.LatLng(53.558572, 9.9278215)}
                                    radius="20"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <Geosuggest
                                    fixtures={fixtures}
                                    placeholder={ this.state.pointB ? (this.state.pointB.location ? "Choose friend location" : "Second place") : "Second place" }
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onChange={this.onChange}
                                    onSuggestSelect={this.onSuggestSelectPointB}
                                    onSuggestNoResults={this.onSuggestNoResults}
                                    location={new google.maps.LatLng(53.558572, 9.9278215)}
                                    radius="20"
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6 col-sm-6 mb-4">         
                                <Dropdown                  
                                    title="Travel mode"
                                    list={this.state.travelMode}
                                    resetThenSet={this.resetThenSet}
                                />
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <Dropdown                  
                                    title="Interest type"
                                    list={this.state.interestTypesPlace}
                                    resetThenSet={this.resetInterestPlaceThenSet}
                                />
                            </div>
                        </div> 
                        <div className="row mb-4">
                            <div className="col-md-6 col-sm-6">
                                <Dropdown                  
                                    title="Radius perimeter"
                                    list={this.state.radiusPerimeter}
                                    resetThenSet={this.resetRadiusPerimeterThenSet}
                                />
                            </div>
                        </div> */}
                        <div className="row mb-4">                            
                            <div className="col-md-12 col-sm-12">
                                <p>Making some upgrades and coming right back !</p>
                            </div>
                        </div>
                        {/* <div className="row mb-4">
                            <div className="col-md-12 col-sm-12">
                                <GoogleMapExample
                                    containerElement={<div style={{ height: '500px', width: '100%' }} />}
                                    mapElement={ <div style={{ height: '100%' }} /> }                                
                                />                            
                            </div>
                        </div>                        */}
                        {/* <div>                            
                            { this.renderInterestList() }                        
                        </div>                   */}
                        {/* Adsterra ads container */}
                        {/* <div id="container-f8a15bd97cd15901c009a9783e4cff83"></div> */}
                        {/* Adsterra ads container */}
                        <hr></hr>
                        <div className="row footer">
                            <div className="col-md-4 col-sm-4">
                                <a href="https://twitter.com/UMeetMeHere" target="blank">Tweet me</a>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <a href="https://www.termsfeed.com/live/2504df22-51b3-4355-a408-0c7e63535c01" target="blank">Disclaimer</a>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <a href="https://icons8.com/icon/IFhxBaYSUYkJ/marker" target="blank">Marker icon by Icons8</a>
                            </div>                    
                        </div>
                    </div>
                </div>
            </HttpsRedirect>
        );
    }
}

export default App;
