import React, { Component } from 'react';


class Messages extends Component{
    
  constructor(props){
    super(props)
    this.state = {
    }
  }
    
  render(){
      const message = this.props.message;
      const style = message.isShowed ? {} : {display: 'none'};

    return(
        <div className="row" style={ style }>
            <div className="col-md-12 col-sm-12 message-alert">
                <p>{ message.messageContent }</p>
            </div>
        </div>
    )
  }
}


export default Messages;