/* global google */
import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';

import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from "@material-ui/core/styles";

import ShareButtons from '../share/ShareButtons.js';

const StyledRating = withStyles({
    iconFilled: {
      color: "#FFB74D"
    }
})(Rating);

class InterestPlaceMarker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            activeMarker: this.props.activeMarker
        };
    }

    /**
      * Toggle open and close on info window
      */
    toggleOpen = () => {
        this.setState({ isOpen: !this.state.isOpen }, () => {
            if (!this.state.isOpen) {
                this.setState({ activeMarker: false }, () => {
                    this.props.closeMarkers(null)
                })
            } else {

                // Google event tag
                window.gtag('event', 'showDetails', {
                    'event_category': 'POI',
                    'event_label': 'Show point of interest\'s details with map markers'
                });

                this.props.closeMarkers(this.props.id)
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ activeMarker: nextProps.activeMarker })
    }

    render() {
        // from https://img.icons8.com/officel/30/000000/marker.png
        let markerIconBas64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAACoklEQVRIx2NgGCrgrJ+fyqWQkLJrUVEdIHw5PLwUJEYTy/4zMDBeCQtLvZuefvhVVdWHr729/7/394MxiA0SA8oduRwamgxSSxVLzwcECNyIj9/+saXlN8wyXPhjW9uvm3FxW8+EhvJTZOkJT0++28nJJ78h+ZAQBqm9nZR04oi1NS/ZFgNdv4UUS+GW9/X9vxEXt5EsS4EJKPpdU9N3XAa/a2gAYxAbm5oPzc3fr4SHR5Bs8b309F3YDHzT2vb/4ey5/1+evQjGD2fNAYq1YrX8XlraTpIsPeTpKfq8tPQlhi/a2/8/27rj/6df/1HwU6DYx44ODIuflZa+OOnsLEy0xef8/PyAKRTDoIdA36JbCsMgOYxUDgyJc76+nkRbfDU4uOgrlkT1aNIUnBY/mjgZQz3IDJBZRFt8MTw860t3N8U+BplxISQknfig9vKyA6bKP9ji+OmW7ZhxDBTDFsfvgGac9vS0JtriY5aWnE8KCh5iT9Wt4JT88vR5MH44azbOVA0048F+BwcOklL27ZSUzfgKiHeNjWCMKx+D8J3UVNILkathYcXY4plYDNJ7OSwsj2SLQWXtk8LCB+Ra/Cg//x4wmHnIKjavRkXNwxeU+KLianT0dLIriQs+PtLPSkqekGrx05KSx6e8vCQoqhqvx8bO+kpCDfW1p+f/NUp8i1wnP8rNvUisxQ+ys6+ecXHhp0or5GpkZPqHlpafhCx939Ly41pERDJV2103YmO3EkpQwMbfNqo3+M64u0s+zMu7jsviexkZt074+YnTpLUJalG8qa19j27pq+rq9xdDQiIYaAmAwTnhc2fnX5ilQPYfoFgfzRv0/xsamIBZbDsoi4HwNWDcg8To0puANnuP3U1JOUlRM5bMhr4CCDMMNQAAmNFseO05LyEAAAAASUVORK5CYII='
        
        return (
            <div>
                <Marker onClick={this.toggleOpen}
                    position={this.props.position}
                    defaultAnimation={2}
                    icon={markerIconBas64}
                >
                    { this.state.isOpen && this.state.activeMarker ?
                        <InfoWindow defaultPosition={this.props.position}
                            onCloseClick={this.toggleOpen}
                            options={{ borderRadius: '.15em' }}
                            style={{ heigt: '1000px' }}>
                            <div id='infoWindowContainer'>
                                <div id="iw-container-header">
                                    <div className="iw-title">
                                        {this.props.place.name}
                                    </div>
                                </div>

                                { this.props.place.photos
                                    ? <div id="iw-container-img" className="col-md-12 col-sm-12 col-xs-12">
                                        <img className="iw-img" src={ this.props.place.photos[0].getUrl() } alt={this.props.place.name}
                                            style={{ display: 'block', width: '100%', height: 'auto' }}></img>
                                    </div>
                                    : null
                                }
                                <div id="iw-container">
                                    <div className="iw-subTitle">
                                        {this.props.place.address}
                                    </div>
                                    <hr></hr>

                                    <div className="d-flex">
                                        <div className="mr-auto" style={{paddingTop: 10}}>
                                                { this.props.place.rating 
                                                    ? <StyledRating
                                                        name="user-rating"
                                                        value={ this.props.place.rating } 
                                                        precision={0.5}
                                                        readOnly={ true }
                                                        size="small"
                                                        />                                     
                                                    : <p style={{ color: '#747d84', marginBottom: 0, fontSize: 5 }}>Not rated</p>}
                                            </div>

                                            <div className="iw-subTitle">
                                                { this.props.place.price_level 
                                                    ? <StyledRating
                                                        name="price-rating"
                                                        value={ this.props.place.price_level } 
                                                        precision={0.5}
                                                        readOnly={ true }
                                                        size="small"
                                                        icon={ <FontAwesomeIcon icon="dollar-sign" size="xs" /> }
                                                    />                                        
                                                    : <p style={{ color: '#747d84', marginBottom: 0 }}>No pricing</p>}
                                            </div>
                                    </div>
                                    
                                    <hr></hr>

                                    <div className="iw-subTitle">
                                        <ShareButtons location={ this.props.place } shareTitle='' />
                                    </div>


                                    
                                </div>

                               
                            </div>
                        </InfoWindow> 
                        : null
                    }
                </Marker>
            </div>
        )
    }
}

export default InterestPlaceMarker;
