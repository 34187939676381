
import React, { Component } from 'react';
import {
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';

import {
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

class ShareButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.location,
      shareTitle: this.props.shareTitle
    };
  }

  googleAnalyticsShareAction(shareType) {
    // Google event tag
    window.gtag('event', 'share', {
      'event_category': 'Social',
      'event_label': 'Shared a point of interest via ' + shareType
    });
  }

  render() {

    const formatedAddress = (this.state.location.address.split(',').join('')).split(' ').join('+');
    const googleMapUrl = 'https://www.google.com/maps/search/?api=1&query=' + formatedAddress + '&query_place_id=' + this.state.location.place_id;
    const sharedText = 'Hey! Meet me there: ' + googleMapUrl;

    return (
      <div>
        <p>{this.state.shareTitle}</p>
        <div className="social-button-align">
          <div style={{ height: '32px', width: '32px', cursor: 'pointer' }}>
            <WhatsappShareButton url={sharedText} beforeOnClick={() => this.googleAnalyticsShareAction('Whatsapp')}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
        <div className="social-button-align">
          <div style={{ height: '32px', width: '32px', cursor: 'pointer' }}>
            <TelegramShareButton url={sharedText} beforeOnClick={() => this.googleAnalyticsShareAction('Telegram')}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
          </div>
        </div>
        <div className="social-button-align">
          <div style={{ height: '32px', width: '32px', cursor: 'pointer' }}>
            <EmailShareButton url={sharedText} beforeOnClick={() => this.googleAnalyticsShareAction('Email')}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </div>
        </div>
      </div>
    )
  };
}

export default ShareButtons;