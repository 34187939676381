import React, { Component } from 'react';

import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from "@material-ui/core/styles";

import ShareButtons from './share/ShareButtons.js';

const StyledRating = withStyles({
    iconFilled: {
      color: "#FFB74D"
    }
})(Rating);

class InterestList extends Component{
    constructor(props) {
        super(props);
        this.state = { 
            places: this.props.places,
            activeMarker: this.props.activeMarker,
            choosenInterestType: this.props.choosenInterestType,
            sortedPlaces: []
        };
    }

    render() {
        const list = this.props.places.map( (place, index) => {
            return (
                    <div className="card" style={{ borderRadius: '.15rem' }} key={index} id={place.id}>
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="mr-auto">
                                    <h5 className="card-title interest-list-title">{ place.name }</h5>
                                </div>                                
                                <div className="p-1">
                                    <FontAwesomeIcon icon={ this.props.choosenInterestType.icon } size='xs' color='#0393AE' />
                                </div>
                            </div>     
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>{ place.address }</p>
                                </div>
                            </div>

                            <hr></hr>
                            <ShareButtons location={ place } shareTitle=''/>
                            
                            <hr></hr>
                            <div className="d-flex">
                                <div className="mr-auto">
                                    { place.rating 
                                        ? <StyledRating
                                            name="user-rating"
                                            value={ place.rating } 
                                            precision={0.5}
                                            readOnly={ true }
                                            size="small"
                                            />                                     
                                        : <p style={{ color: '#747d84', marginBottom: 0, fontSize: '.9em'  }}>Not rated</p>}
                                </div>
                                <div>
                                    { place.price_level 
                                        ? <StyledRating
                                            name="price-rating"
                                            value={ place.price_level } 
                                            precision={0.5}
                                            readOnly={ true }
                                            size="small"
                                            icon={ <FontAwesomeIcon icon="dollar-sign" size="xs" /> }
                                        />                                        
                                        : <p style={{ color: '#747d84', marginBottom: 0, fontSize: '.9em' }}>No pricing</p>}
                                </div>
                            </div>
                            
                        </div>
                    </div>
            )
        });

        const title = this.props.places.length + ' ' +  this.props.choosenInterestType.title.toLowerCase() + (this.props.places.length > 1 ? 's where to meet !' : 'where to meet... only') + '';

        return(
            <div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="d-flex">
                            <div className="mr-auto">
                            </div>
                            <div>                                    
                                <p style={{ color: '#074C82'}}>{ title  }</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-columns">
                            { list }
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default InterestList;